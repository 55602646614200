@import 'mapbox-gl/dist/mapbox-gl.css';

/* You can add global styles to this file, and also import other style files */
$theme-colors: (
  'primary': #163172,
  // 'danger': #ff4136
);

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 80px;
}

.form-control-alternative {
  background-color: #f3f5f6;
  color: #000;
  // box-shadow: none;
}

.form-control-alternative:focus {
  background-color: #f3f5f6;
  color: #000;
  // box-shadow: none;
}

.list-group.admission-list {
  .custom-select {
    min-width: 50px;
  }

  .list-group-item {
    border-top-right-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-top-width: 0.0625rem !important;
  }

  .list-group-item + .list-group-item {
    border-top-width: 0.0625rem !important;
  }
}
